<script setup lang="ts">
// eslint-disable-next-line regex/invalid
import { SvgoHpHeroBusPrimary50, SvgoHpHeroBusPrimary500, SvgoHpHeroBusSecondary500 } from '#components';
import { AssetType } from '~/apiClient';

// COMPOSABLES
const { tt } = useTypedI18n();
const { formatAlpRoute } = useAlpRouteUtils();
const localePath = useLocalePath();
</script>

<template>
    <BlockHero :title="tt('homepage.hero.title')" :subtitle="tt('homepage.hero.subtitle')">
        <template #cover>
            <div class="relative left-1/2 mx-auto flex -translate-x-1/2 flex-nowrap items-center justify-center gap-5 md:gap-x-14">
                <SvgoHpHeroBusPrimary50 filled :font-controlled="false" class="w-[219px] md:w-[526px]" />
                <SvgoHpHeroBusPrimary500 filled :font-controlled="false" class="w-[290px] md:w-[537px]" />
                <SvgoHpHeroBusSecondary500 filled :font-controlled="false" class="w-[210px] md:w-[506px]" />
            </div>
        </template>
        <template #cta>
            <UButton
                size="xl"
                color="primary"
                :label="tt('homepage.hero.buttonLabel')"
                :to="localePath(formatAlpRoute({ assetType: AssetType.Bus }))"
            />
        </template>
    </BlockHero>
</template>
