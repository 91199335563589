<script setup lang="ts">
// eslint-disable-next-line regex/invalid
import { SvgoHpWhatIsProcess, SvgoHpWhatIsSpeed, SvgoHpWhatIsTransparency } from '#components';
import type { BlockFeatThreeColItem } from '~/components/Block/BlockFeatThreeCol.vue';

// COMPOSABLES
const { tt } = useTypedI18n();

// DATA
const items = computed<BlockFeatThreeColItem[]>(() => [
    {
        icon: SvgoHpWhatIsProcess,
        title: tt('homepage.whatIsFleequid.section.simplicity.title'),
        description: tt('homepage.whatIsFleequid.section.simplicity.description'),
    },
    {
        icon: SvgoHpWhatIsSpeed,
        title: tt('homepage.whatIsFleequid.section.speed.title'),
        description: tt('homepage.whatIsFleequid.section.speed.description'),
    },
    {
        icon: SvgoHpWhatIsTransparency,
        title: tt('homepage.whatIsFleequid.section.transparency.title'),
        description: tt('homepage.whatIsFleequid.section.transparency.description'),
    },
]);
</script>

<template>
    <BlockFeatThreeCol :title="tt('homepage.whatIsFleequid.title')" :description="tt('homepage.whatIsFleequid.description')" :items="items" />
</template>
