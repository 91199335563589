<script setup lang="ts">
import { AssetType } from '~/apiClient';

// COMPOSABLES
const { tt } = useTypedI18n();
const { formatAlpRoute } = useAlpRouteUtils();
const localePath = useLocalePath();
</script>

<template>
    <BlockCtaJustify :title="tt('homepage.startToday.title')" :description="tt('homepage.startToday.description')">
        <template #cta>
            <UButton size="xl" class="min-w-40" :to="localePath(formatAlpRoute({ assetType: AssetType.Bus }))" block>{{
                tt('homepage.startToday.cta')
            }}</UButton>
        </template>
    </BlockCtaJustify>
</template>
