<script setup lang="ts">
const { tt } = useTypedI18n();
</script>

<template>
    <BaseWidthContainer class="flex flex-col gap-8 font-medium">
        <div class="flex flex-col gap-8 md:grid md:grid-cols-2">
            <h4 class="text-center text-3xl font-semibold leading-9 tracking-normal md:text-left md:text-4xl md:leading-10">
                {{ tt('homepage.howItWorks.title') }}
            </h4>
            <p class="md:text-leading-8 text-xl leading-7 text-neutral-600 md:text-2xl">{{ tt('homepage.howItWorks.description') }}</p>
        </div>
        <div class="flex flex-col gap-8 border-t border-solid border-neutral-300 py-9 md:grid md:grid-cols-2">
            <div class="flex items-center gap-4">
                <span class="bg-primary flex h-9 w-9 items-center justify-center rounded-full text-white" size="lg">1</span>
                <h5 class="text-xl leading-7 md:text-2xl md:leading-8">{{ tt('homepage.howItWorks.signUp.title') }}</h5>
            </div>
            <div class="flex flex-col gap-8 md:text-lg md:leading-7">
                <i18n-t scope="global" tag="p" class="" :keypath="'homepage.howItWorks.signUp.create'">
                    <template #account>
                        <span class="text-primary">{{ tt('homepage.howItWorks.signUp.account') }}</span>
                    </template>
                </i18n-t>
                <i18n-t scope="global" tag="p" :keypath="'homepage.howItWorks.signUp.verify'">
                    <template #browse>
                        <span class="text-primary">{{ tt('homepage.howItWorks.signUp.browse') }}</span>
                    </template>
                </i18n-t>
            </div>
        </div>
        <div class="flex flex-col gap-8 border-t border-solid border-neutral-300 py-9 md:grid md:grid-cols-2">
            <div class="flex items-center gap-4">
                <span class="bg-primary flex h-9 w-9 items-center justify-center rounded-full text-white" size="lg">2</span>
                <h5 class="text-xl leading-7 md:text-2xl md:leading-8">{{ tt('homepage.howItWorks.join.title') }}</h5>
            </div>
            <div class="flex flex-col gap-8 md:text-lg md:leading-7">
                <i18n-t scope="global" tag="p" :keypath="'homepage.howItWorks.join.choose'">
                    <template #bus>
                        <span class="text-primary">{{ tt('homepage.howItWorks.join.bus') }}</span>
                    </template>
                </i18n-t>
                <i18n-t scope="global" tag="p" :keypath="'homepage.howItWorks.join.add'">
                    <template #payment>
                        <span class="text-primary">{{ tt('homepage.howItWorks.join.payment') }}</span>
                    </template>
                </i18n-t>
                <i18n-t scope="global" tag="p" :keypath="'homepage.howItWorks.join.follow'">
                    <template #win>
                        <span class="text-primary">{{ tt('homepage.howItWorks.join.win') }}</span>
                    </template>
                </i18n-t>
            </div>
        </div>
        <div class="flex flex-col gap-8 border-b border-t border-solid border-neutral-300 py-9 md:grid md:grid-cols-2">
            <div class="flex items-center gap-4">
                <span class="bg-primary flex h-9 w-9 items-center justify-center rounded-full text-white" size="lg">3</span>
                <h5 class="text-xl leading-7 md:text-2xl md:leading-8">{{ tt('homepage.howItWorks.sell.title') }}</h5>
            </div>
            <div class="flex flex-col gap-8 md:text-lg md:leading-7">
                <i18n-t scope="global" tag="p" :keypath="'homepage.howItWorks.sell.letUsKnow'">
                    <template #bus>
                        <span class="text-primary">{{ tt('homepage.howItWorks.sell.bus') }}</span>
                    </template>
                </i18n-t>
                <i18n-t scope="global" tag="p" :keypath="'homepage.howItWorks.sell.choose'">
                    <template #price>
                        <span class="text-primary">{{ tt('homepage.howItWorks.sell.price') }}</span>
                    </template>
                </i18n-t>
                <i18n-t scope="global" tag="p" :keypath="'homepage.howItWorks.sell.wait'">
                    <template #confirm>
                        <span class="text-primary">{{ tt('homepage.howItWorks.sell.confirm') }}</span>
                    </template>
                </i18n-t>
            </div>
        </div>
    </BaseWidthContainer>
</template>
