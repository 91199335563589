<script setup lang="ts">
// COMPOSABLES
const { tt } = useTypedI18n();
useSeoMeta({
    title: tt('seo.homepage.title'),
    ogTitle: tt('seo.homepage.title'),
    description: tt('seo.homepage.description'),
    ogDescription: tt('seo.homepage.description'),
});
</script>

<template>
    <div class="mb-12 flex flex-col gap-y-12 sm:gap-y-16 xl:gap-y-20">
        <HpHero />
        <HpWhatIsFleequid />
        <HpVision />
        <HpHowItWorks />
        <HpWhyFleequid />
        <HpStartToday />
    </div>
</template>
