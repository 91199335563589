<script setup lang="ts">
// COMPOSABLES
import { Locale } from '~/i18n/i18nConfig';

const { tt } = useTypedI18n();
const { locale } = useI18n();

// DATA
const image = computed<string>(() => (locale.value === Locale.IT ? `/hp/vision_it.png` : '/hp/vision_en.png'));
</script>

<template>
    <BlockCtaImageText
        :caption="tt('homepage.vision.caption')"
        :title="tt('homepage.vision.title')"
        :highlight="tt('homepage.vision.highlight')"
        :image="image"
    />
</template>
